import { DataTable, HFlow, TableFooter, Text, VFlow } from 'bold-ui'
import { Cns, Cpf } from 'components/label'
import { TableBox } from 'components/table'
import { usePagination } from 'components/table/usePagination'
import { css } from 'emotion'
import { Cidadao } from 'graphql/types.generated'
import React from 'react'
import { calcularIMC } from 'util/atendimento'
import { toDate } from 'util/date/formatDate'
import { humanizeAge } from 'util/date/humanize-age'
import { formatNumber } from 'util/number'
import { AlertImc } from 'view/atendimentos/detail/soap/aside/medicoes/components/AlertImc'

interface CidadaoParticipanteModel
  extends Pick<Cidadao, 'nome' | 'nomeSocial' | 'cpf' | 'cns' | 'dataNascimento' | 'sexo'> {}

interface ParticipanteTableModel {
  cidadao?: CidadaoParticipanteModel
  cidadaoSemCadastro?: CidadaoParticipanteModel
  peso?: number
  altura?: number
  avaliacaoAlterada: boolean
  pnctAbandonouGrupo?: boolean
  pnctCessouHabitoFumar?: boolean
}

interface ParticipantesTableProps {
  items: ParticipanteTableModel[]
  dataAtividade: LocalDate
  isPraticaSaudePnct: boolean
}

export function ParticipantesTable(props: ParticipantesTableProps) {
  const { items, dataAtividade, isPraticaSaudePnct } = props
  const { paginatedItems, tableProps } = usePagination({ items })

  const infoCidadaoColumn = (row: ParticipanteTableModel) => {
    const cidadaoInfoColumn = row.cidadao
      ? {
          ...row.cidadao,
          nome: row.cidadao?.nomeSocial ?? row.cidadao?.nome,
        }
      : row.cidadaoSemCadastro

    return (
      <VFlow vSpacing={0.1} style={styles.tableRowCidadao}>
        <Text fontWeight='bold'>{cidadaoInfoColumn.nome?.titleCase()}</Text>
        {(cidadaoInfoColumn?.cpf && <Cpf value={cidadaoInfoColumn.cpf} />) ||
          (cidadaoInfoColumn?.cns && <Cns value={cidadaoInfoColumn.cns} />)}
        <Text>
          {cidadaoInfoColumn.dataNascimento && humanizeAge(cidadaoInfoColumn.dataNascimento)} |{' '}
          {cidadaoInfoColumn.sexo?.capitalize()}
        </Text>
      </VFlow>
    )
  }

  const renderImc = (row: ParticipanteTableModel) => {
    const imc = calcularIMC(row.peso, row.altura)

    return (
      <HFlow alignItems='center'>
        <Text>{formatNumber(imc)}</Text>
        <AlertImc
          dataMedicao={toDate(dataAtividade).getTime()}
          imc={formatNumber(imc)}
          dataNascimento={row.cidadao?.dataNascimento ?? row.cidadaoSemCadastro?.dataNascimento}
          sexo={row.cidadao?.sexo ?? row.cidadaoSemCadastro?.sexo}
        />
      </HFlow>
    )
  }

  return (
    <TableBox>
      <DataTable<ParticipanteTableModel>
        rows={paginatedItems}
        columns={[
          {
            name: 'nome',
            header: 'Nome',
            render: (row) => infoCidadaoColumn(row),
            style: styles.tableRowNome,
          },
          {
            name: 'avaliacaoAlterada',
            header: 'Avaliação alterada',
            render: (row) => (row.avaliacaoAlterada ? 'Sim' : 'Não'),
          },
          {
            name: 'peso',
            header: 'Peso (Kg)',
            render: (row) => (row.peso ? formatNumber(row.peso) : '-'),
          },
          {
            name: 'altura',
            header: 'Altura (cm)',
            render: (row) => (row.altura ? row.altura : '-'),
          },
          {
            name: 'imc',
            header: 'IMC',
            render: (row) => (row.peso && row.altura ? renderImc(row) : '-'),
            style: styles.tableRowIMC,
          },
          isPraticaSaudePnct && {
            name: 'pnctAbandonouGrupo',
            header: 'Abandonou o grupo?',
            render: (row) => (row.pnctAbandonouGrupo ? 'Sim' : 'Não'),
            style: styles.tableRowPnct,
          },
          isPraticaSaudePnct && {
            name: 'pnctCessouFumar',
            header: 'Cessou o hábito de fumar?',
            render: (row) => (row.pnctCessouHabitoFumar ? 'Sim' : 'Não'),
            style: styles.tableRowPnct,
          },
        ].filterNotFalsy()}
      />
      <TableFooter style={styles.tableFooter} {...tableProps} />
    </TableBox>
  )
}

const styles = {
  tableFooter: css`
    border-top: 0;
  `,
  tableRowNome: css`
    width: 15rem;
  `,
  tableRowCidadao: css`
    margin: 1rem 0;
  `,
  tableRowIMC: css`
    width: 20rem;
  `,
  tableRowPnct: css`
    width: 9rem;
  `,
}
