import { CidadaoFciInput, NacionalidadeEnum, RacaCorDbEnum, SexoEnum } from 'graphql/types.generated'

import { CidadaoFciFormModel } from './model-cidadaoFci'

export function convertToCidadaoFciInput(model: CidadaoFciFormModel): CidadaoFciInput {
  return {
    id: model.id,
    nome: 'Nome Fake',
    sexo: SexoEnum.MASCULINO,
    dataNascimento: '2000-01-01',
    nacionalidade: NacionalidadeEnum.BRASILEIRA,
    racaCor: RacaCorDbEnum.BRANCA,
  }
}
