import { Button } from 'bold-ui'
import { useErrorHandler } from 'components/error'
import { Form, FormFooter, FormRenderProps, SubmitButton } from 'components/form'
import { useSalvarCidadaoFciMutation } from 'graphql/hooks.generated'
import React from 'react'
import { useHistory } from 'react-router'

import { convertToCidadaoFciInput } from '../types/converter-cidadaoFci'
import { CidadaoFciFormModel } from '../types/model-cidadaoFci'

interface CidadaoFciFormProps {
  initialValues?: CidadaoFciFormModel
}

export function CidadaoFciDadosForm({ initialValues }: CidadaoFciFormProps) {
  const [save, { loading }] = useSalvarCidadaoFciMutation()
  const handleRejection = useErrorHandler()
  const history = useHistory()

  const handleSubmit = (values: CidadaoFciFormModel) =>
    save({ variables: { input: convertToCidadaoFciInput(values) } })
      .then(() => {
        setTimeout(() => history.push(`/cidadao`))
      })
      .catch(handleRejection)

  const renderForm = (formProps: FormRenderProps<CidadaoFciFormModel>) => {
    return (
      <FormFooter>
        <Button data-cy='FormFooter.cancelar'>Cancelar</Button>
        <SubmitButton
          handleSubmit={formProps.handleSubmit}
          kind='primary'
          data-testid='FormFooter.salvar'
          loading={loading}
        >
          Salvar
        </SubmitButton>
      </FormFooter>
    )
  }

  return <Form<CidadaoFciFormModel> onSubmit={handleSubmit} render={renderForm} initialValues={initialValues} />
}
