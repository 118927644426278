import { TipoAtividadeEnum } from 'graphql/types.generated'
import { MetaRoot } from 'util/metaPath'

import {
  AtividadeColetivaFormModel,
  PRATICAS_SAUDE_ATENDIMENTO_EM_GRUPO,
  PRATICAS_SAUDE_EDUCACAO_PSE,
  TIPOS_ATIVIDADE_EM_SAUDE_EDUCACAO_PSE,
} from '../model-atividadeColetiva'
import {
  isOnlyEducacaoPseSelected,
  isPraticaSaudeDisabled,
  isProcedimentoDisabled,
} from './utils-atividadeColetivaSaude'

export const createAtividadeColetivaSaudeCalculations = (path: MetaRoot<AtividadeColetivaFormModel>) => [
  {
    field: path.atividadeEducacaoPse.absolutePath(),
    updates: {
      [path.localAtividade.outraLocalidade.absolutePath()]: () => null,
      [path.localAtividade.unidadeSaudeLocalAtividade.absolutePath()]: () => null,
      [path.lotacoesEnvolvidas.absolutePath()]: clearLotacoesEnvolvidasIfOnlyEducacaoPseSelected,
      [path.praticasSaude.absolutePath()]: filterPraticasSaudeIfOnlyEducacaoPseSelected,
      [path.tipoAtividade.absolutePath()]: clearTipoAtividadeIfOnlyEducacaoPseSelected,
    },
  },
  {
    field: path.atividadeSaudePse.absolutePath(),
    updates: {
      [path.localAtividade.outraLocalidade.absolutePath()]: () => null,
      [path.localAtividade.unidadeSaudeLocalAtividade.absolutePath()]: () => null,
      [path.lotacoesEnvolvidas.absolutePath()]: clearLotacoesEnvolvidasIfOnlyEducacaoPseSelected,
      [path.praticasSaude.absolutePath()]: filterPraticasSaudeIfOnlyEducacaoPseSelected,
      [path.tipoAtividade.absolutePath()]: clearTipoAtividadeIfOnlyEducacaoPseSelected,
    },
  },
  {
    field: path.tipoAtividade.absolutePath(),
    updates: {
      [path.praticasSaude.absolutePath()]: (_, allValues: AtividadeColetivaFormModel) =>
        isPraticaSaudeDisabled(allValues)
          ? null
          : allValues.tipoAtividade === TipoAtividadeEnum.ATENDIMENTO_EM_GRUPO
          ? allValues.praticasSaude?.filter((pratica) => PRATICAS_SAUDE_ATENDIMENTO_EM_GRUPO.includes(pratica))
          : allValues.praticasSaude,
    },
  },
  {
    field: path.praticasSaude.absolutePath(),
    updates: {
      [path.procedimento.absolutePath()]: (_, allValues: AtividadeColetivaFormModel) =>
        isProcedimentoDisabled(allValues) ? null : allValues.procedimento,
    },
  },
]

const filterPraticasSaudeIfOnlyEducacaoPseSelected = (_, allValues: AtividadeColetivaFormModel) =>
  isOnlyEducacaoPseSelected(allValues)
    ? allValues.praticasSaude?.filter((pratica) => PRATICAS_SAUDE_EDUCACAO_PSE.includes(pratica))
    : allValues.praticasSaude

const clearTipoAtividadeIfOnlyEducacaoPseSelected = (_, allValues: AtividadeColetivaFormModel) =>
  isOnlyEducacaoPseSelected(allValues) && !TIPOS_ATIVIDADE_EM_SAUDE_EDUCACAO_PSE.includes(allValues.tipoAtividade)
    ? null
    : allValues.tipoAtividade

const clearLotacoesEnvolvidasIfOnlyEducacaoPseSelected = (_, allValues: AtividadeColetivaFormModel) =>
  isOnlyEducacaoPseSelected(allValues) ? null : allValues.lotacoesEnvolvidas
