import { HeadingSection } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import React from 'react'

import { CidadaoFciDadosForm } from './CidadaoFciDadosForm'

export function CidadaoFciCreateView() {
  return (
    <PageContent type='filled'>
      <Breadcrumb title='Cadastro do cidadão' />
      <HeadingSection level={1} title='Cadastro do cidadão'>
        <>Novo cadastro do cidadão</>
      </HeadingSection>
      <CidadaoFciDadosForm />
    </PageContent>
  )
}
