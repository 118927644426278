import { LotacaoEditableTableModel } from 'components/form/editable-table/LotacaoEditableTable'
import { LotacaoResponsavelFormModel } from 'components/lotacao-responsavel/model-lotacaoResponsavel'
import { subYears } from 'date-fns'
import {
  afterEqualDate,
  beforeEqualToday,
  createValidator,
  maxLength,
  range,
  required,
  richTextMaxLength,
} from 'util/validation'

import { LocalAtividadeFieldModel } from './componentes/LocalAtividadeField'

const localAtividadeValidator = createValidator<LocalAtividadeFieldModel>({
  outraLocalidade: [maxLength(250)],
})

export const getAtividadeColetivaCommonValidationRules = (now: Date) => ({
  lotacaoResponsavel: [required],
  dataAtividade: [required, beforeEqualToday, afterEqualDate(subYears(now, 1))],
  turno: [required],
  numeroParticipantes: [required, range(1, 999)],
  localAtividade: localAtividadeValidator,
  tipoAtividade: [required],
  anotacoes: [richTextMaxLength(4000)],
})

export const validateLotacoes = (
  lotacaoResponsavel: LotacaoResponsavelFormModel,
  lotacoesEnvolvidas: LotacaoEditableTableModel[]
) => {
  const lotacaoResponsavelInLotacoes = lotacoesEnvolvidas?.some(
    (lotacaoEnvolvida) => lotacaoEnvolvida.lotacao.profissional.id === lotacaoResponsavel?.lotacao?.profissional.id
  )

  return lotacaoResponsavelInLotacoes && 'Profissional responsável não pode ser profissional envolvido'
}
