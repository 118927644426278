/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Cell, Grid, Heading, HFlow, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb/Breadcrumb'
import { Form, FormRenderProps } from 'components/form'
import { PageContent } from 'components/layout/PageContent'
import { RelatoriosButtonGroup } from 'components/relatorios-buttons/RelatoriosButtonGroup'
import { TableBox, usePagedTableProps } from 'components/table'
import { TitleGroup } from 'components/TitleGroup'
import { useBuscaAtivaVacinacaoLazyQuery } from 'graphql/hooks.generated'
import {
  ArquivoIdentificadorEnum,
  ArquivoStatusEnum,
  TipoVisualizacaoBuscaAtivaVacinacaoEnum,
} from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { Fragment, useEffect, useState } from 'react'
import Permissions from 'types/Permissions'
import { RelatoriosRecentes } from 'view/arquivo/RelatoriosRecentes'

import BuscaAtivaVacinacaoForm from './BuscaAtivaVacinacaoForm'
import { calculator } from './calculator-buscaAtivaVacinacao'
import BuscaAtivaVacinacaoActiveFilters from './component/BuscaAtivaVacinacaoActiveFilters'
import BuscaAtivaVacinacaoCidadaosEncontradosTable from './component/BuscaAtivaVacinacaoCidadaosEncontradosTable'
import convertModelToInput from './convertModelToInput'
import { downloadCSVBuscaAtivaVacinacao, downloadImpressaoBuscaAtivaVacinacao } from './downloadBuscaAtivaVacinacao'
import { BuscaAtivaVacinacaoFormModel } from './model-buscaAtivaVacinacao'
import { validator } from './validator-buscaAtivaVacinacao'

export default function BuscaAtivaVacinacaoView() {
  const [executeQuery, { loading, data }] = useBuscaAtivaVacinacaoLazyQuery()
  const { hasAuthorization } = useSession()
  const theme = useTheme()
  const styles = createStyles(theme)
  const { acesso } = useAcessoLotacaoOrEstagio()
  const { analytics } = useFirebase()

  const [statusRelatorio, setStatusRelatorio] = useState<ArquivoStatusEnum>()
  const [activeFilters, setActiveFilters] = useState<BuscaAtivaVacinacaoFormModel>()
  const [shouldRefetch, setShouldRefetch] = useState(false)

  const initialValues: BuscaAtivaVacinacaoFormModel = {
    tipoVisualizacao: TipoVisualizacaoBuscaAtivaVacinacaoEnum.CALENDARIO_VACINAL,
  }

  const { unidadeSaude, equipe } = acesso

  const isCoordenador = hasAuthorization(Permissions.buscaAtivaDeVacinacao.todasAsEquipes)

  const isAbleToBuscar = isCoordenador || equipe?.id

  const identificadorModuloArquivo = ArquivoIdentificadorEnum.RELATORIO_BUSCA_ATIVA_VACINACAO

  const tableProps = usePagedTableProps({
    result: data?.buscaAtivaVacinacao,
    loading: loading,
    onChange: setActiveFilters,
  })

  const afterSubmit = () => {
    setShouldRefetch(true)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleExportarPDFClick = () => {
    analytics.logEvent('exportar_pdf_busca_ativa_vacinacao')
    downloadImpressaoBuscaAtivaVacinacao(convertModelToInput(activeFilters))
    setTimeout(afterSubmit)
  }

  const handleExportarCSVClick = () => {
    analytics.logEvent('exportar_csv_busca_ativa_vacinacao')
    downloadCSVBuscaAtivaVacinacao(convertModelToInput(activeFilters))
    setTimeout(afterSubmit)
  }

  useEffect(() => {
    if (activeFilters) {
      executeQuery({ variables: { input: { ...convertModelToInput(activeFilters) } } })
    }
  }, [activeFilters, executeQuery])

  return (
    <Fragment>
      <Breadcrumb title='Busca ativa de vacinação' />
      <VFlow style={styles.contentDivider}>
        <PageContent type='filled'>
          <HFlow justifyContent='space-between' style={styles.titleContent}>
            <TitleGroup title='Busca ativa de vacinação' />
            <RelatoriosRecentes
              shouldRefetch={shouldRefetch}
              setShouldRefetch={setShouldRefetch}
              identificadorModuloArquivo={identificadorModuloArquivo}
              setStatusRelatorio={setStatusRelatorio}
            />
          </HFlow>
          <Grid gapVertical={1}>
            <Cell size={12}>
              <Alert
                type='info'
                inline
                styles={{
                  wrapper: styles.alertWrapper,
                  container: styles.alertContainer,
                }}
              >
                A busca ativa de vacinação apresenta cidadãos relacionados a alguma equipe/INE. A origem das informações
                são as fichas de cadastro individual e os cadastros do módulo dos cidadãos.
              </Alert>
            </Cell>
            <Form<BuscaAtivaVacinacaoFormModel>
              render={(formProps: FormRenderProps<BuscaAtivaVacinacaoFormModel>) => (
                <BuscaAtivaVacinacaoForm
                  unidadeSaude={{ id: unidadeSaude.id, nome: unidadeSaude.nome }}
                  equipe={equipe}
                  isCoordenador={isCoordenador}
                  {...formProps}
                />
              )}
              validateOnBlur
              initialValues={initialValues}
              validate={validator}
              onSubmit={(values) => setActiveFilters(values)}
              decorators={[calculator]}
            />
          </Grid>
        </PageContent>
      </VFlow>
      <PageContent>
        {data?.buscaAtivaVacinacao.content || loading ? (
          <Fragment>
            <TitleGroup title='Cidadãos encontrados' style={styles.titleContent} />
            <VFlow vSpacing={0.5}>
              {activeFilters && <BuscaAtivaVacinacaoActiveFilters filters={activeFilters} />}
              <VFlow vSpacing={2}>
                <TableBox>
                  <BuscaAtivaVacinacaoCidadaosEncontradosTable
                    statusVacinacao={activeFilters.status?.enum}
                    tipoVisualizacao={activeFilters.tipoVisualizacao}
                    {...tableProps}
                  />
                </TableBox>
                <RelatoriosButtonGroup
                  shouldRefetch={shouldRefetch}
                  statusRelatorio={statusRelatorio}
                  numberOfElements={data?.buscaAtivaVacinacao?.content.length}
                  onClickExportarCSV={handleExportarCSVClick}
                  onClickexportarPDF={handleExportarPDFClick}
                />
              </VFlow>
            </VFlow>
          </Fragment>
        ) : (
          <VFlow style={styles.mensagemPreBusca}>
            {isAbleToBuscar ? (
              <Fragment>
                <Heading level={3}>
                  Realize uma busca por cidadãos que tenham vacina(s) no prazo, atrasadas ou aplicadas.
                </Heading>
                {isCoordenador ? (
                  <Text>Selecione a equipe responsável, o grupo-alvo e o status da vacina para gerar a listagem.</Text>
                ) : (
                  <Text>Selecione o grupo-alvo e o status da vacina para gerar a listagem.</Text>
                )}
              </Fragment>
            ) : (
              <Heading level={3}>
                Seu perfil não possui acesso aos resultados. Para buscar os dados é necessário estar vinculado a uma
                equipe.
              </Heading>
            )}
          </VFlow>
        )}
      </PageContent>
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  responsavelTitle: css`
    margin-bottom: 0.5rem;
  `,
  mensagemPreBusca: css`
    text-align: center;
    margin-top: 2.5rem;
  `,
  contentDivider: css`
    border-bottom: 1px solid ${theme.pallete.divider};
    padding-bottom: 1.25rem;
  `,
  titleContent: css`
    margin: 1rem 0;
  `,
  alertWrapper: css`
    padding: 0.375rem;
  `,
  alertContainer: css`
    align-items: center;
  `,
})
