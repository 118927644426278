import { Button, HFlow, Icon, Tooltip } from 'bold-ui'
import { ArquivoStatusEnum } from 'graphql/types.generated'
import React from 'react'

const NAO_EXISTEM_DADOS_TEXT = 'Não existem dados para geração do relatório'
const EM_PROCESSAMENTO_TEXT = 'Relatório em processamento'

interface RelatoriosButtonGroupProps {
  shouldRefetch: boolean
  statusRelatorio: ArquivoStatusEnum
  numberOfElements: number
  otherButtons?: React.ReactNode
  onClickExportarCSV?: () => void
  onClickexportarPDF?: () => void
}

export function RelatoriosButtonGroup(props: RelatoriosButtonGroupProps) {
  const {
    shouldRefetch,
    statusRelatorio,
    numberOfElements,
    otherButtons,
    onClickExportarCSV: exportarCSV,
    onClickexportarPDF: exportarPDF,
  } = props
  if (!exportarPDF && !exportarCSV && !otherButtons) {
    return null
  }

  const hasResults = numberOfElements > 0

  const isRelatorioProcessando = shouldRefetch || statusRelatorio === ArquivoStatusEnum.EM_PROCESSAMENTO

  const mensagemTooltipCsvAndPdf = isRelatorioProcessando
    ? EM_PROCESSAMENTO_TEXT
    : !hasResults
    ? NAO_EXISTEM_DADOS_TEXT
    : null

  const shouldDisableCsvAndPdf = !hasResults || isRelatorioProcessando

  return (
    <HFlow justifyContent='flex-end'>
      {exportarCSV && (
        <Tooltip text={mensagemTooltipCsvAndPdf}>
          <Button onClick={exportarCSV} disabled={shouldDisableCsvAndPdf}>
            Exportar CSV
          </Button>
        </Tooltip>
      )}
      {exportarPDF && (
        <Tooltip text={mensagemTooltipCsvAndPdf}>
          <Button kind='primary' onClick={exportarPDF} disabled={shouldDisableCsvAndPdf}>
            <HFlow hSpacing={0.5}>
              <Icon icon='fileWithItemsOutline' />
              Imprimir
            </HFlow>
          </Button>
        </Tooltip>
      )}
      {otherButtons}
    </HFlow>
  )
}
