import { useFlagsQuery } from 'graphql/hooks.generated'
import React, { useContext } from 'react'

export interface FlagsContextModel {
  UNIFICACAO_BASE_ENABLED: boolean
  LISTA_REGISTRO_TARDIO_NOVA_ENABLED: boolean
  BUSCA_ATIVA_VACINACAO_ENABLED: boolean
  OBSERVACAO_ENABLED: boolean
  ASSINATURA_ELETRONICA_ENABLED: boolean
  API_DADOS_DW_ENABLED: boolean
  ENVIO_RAC_ENABLED: boolean
  ENVIO_RPM_ENABLED: boolean
  ENVIO_VACINA_ENABLED: boolean
  ENVIO_RNDS_ENABLED: boolean
  CUIDADO_COMPARTILHADO_ENABLED: boolean
  HISTORICO_CUIDADO_COMPARTILHADO_ENABLED: boolean
  PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED: boolean
  PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED: boolean
  CUIDADO_COMPARTILHADO_CIDADAO_OBITO: boolean
  COOKIE_ENABLED: boolean
  NPS_ENABLED: boolean
  CADASTRO_IMOVEL_ENABLED: boolean
  PRESCRICAO_CONTROLADA: boolean
  IMPRESSAO_ESCUTA_INICIAL_ENABLED: boolean
  PILOTO_RECEBIMENTO_ONLINE_EXTERNO_ENABLED: boolean
  GARANTIA_ACESSO_ENABLED: boolean
  GARANTIA_ACESSO_CIDADAO_OBITO: boolean
  PILOTO_VINCULACAO_SERVICO_1_ENABLED: boolean
  PRESCRICAO_TURNO: boolean
  ATUALIZACAO_SIGTAP_ENABLED: boolean
  ACOMPANHAMENTO_CONDICAO_SAUDE_ENABLED: boolean
  SAUDE_INDIGENA_ENABLED: boolean
  PILOTO_ALERTAS_SDC_1_ENABLED: boolean
  ACOMPANHAMENTO_CONDICAO_SAUDE_CSV: boolean
  ATIVIDADE_COLETIVA_ENABLED: boolean
  RELATORIO_BOLSA_FAMILIA_ENABLED: boolean
  IVCF_ENABLED: boolean
  RETIFICACAO_ATENDIMENTO_PROCEDIMENTOS_ENABLED: boolean
  ULTIMOS_ACESSOS_ENABLED: boolean
  ANEXO_ARQUIVOS: boolean
  GANHO_PESO_GESTACIONAL_ENABLED: boolean
  CIDADAO_FCI_ENABLED: boolean
}

interface FlagsProviderProps {
  children: React.ReactNode
}

export const FlagsContext = React.createContext<FlagsContextModel>(undefined)

export function FlagsProvider({ children }: FlagsProviderProps) {
  const {
    data: { info: { flags } = {} },
  } = useFlagsQuery({
    fetchPolicy: 'cache-first',
  })

  return <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>
}

export const useFlags = () => useContext(FlagsContext)
