import { Button, Cell, FormControl, Grid, Heading, HFlow, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Box } from 'components/Box'
import { Form, SubmitButton, TextField } from 'components/form'
import { confirm } from 'components/modals/confirm'
import React, { useMemo } from 'react'
import { FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'

import {
  BOTAO_TESTAR_TOOLTIP_TEXT,
  ConfiguracaoAnexoArquivosModel,
  DESABILITAR_CONFIRM_BODY_TEXT,
  HABILITAR_CONFIRM_BODY_TEXT,
} from '../model-anexoArquivos'
import { validate } from '../validator-anexoArquivos'

interface HabilitarAnexoArquivosBoxProps {
  isEnabled: boolean
  onToggleEnabled: (enabled: boolean) => void
}

const meta = metaPath<ConfiguracaoAnexoArquivosModel>()

export default function HabilitarAnexoArquivosBox(props: HabilitarAnexoArquivosBoxProps) {
  const { isEnabled, onToggleEnabled } = props

  const alert = useAlert()
  const diretorio = isEnabled === null ? null : 'diretorio'

  const handleSubmit = (formValues: ConfiguracaoAnexoArquivosModel) => {
    const titleText = isEnabled ? 'desabilitar' : 'habilitar'
    const bodyText = isEnabled ? DESABILITAR_CONFIRM_BODY_TEXT : HABILITAR_CONFIRM_BODY_TEXT
    const alertText = isEnabled ? 'novos arquivos desabilitado' : ' arquivos habilitado'

    return confirm({
      title: `Deseja ${titleText} o anexo de arquivos?`,
      body: bodyText,
      onConfirm: () => {
        // TODO (#22241): Salvar as informações da configuração do anexo de arquivos
        onToggleEnabled(!isEnabled)
        alert('success', `Anexo de ${alertText} com sucesso`)
      },
    })()
  }

  const handleTestClick = () => {
    // TODO (#11873): Validação das informações da configuração do anexo de arquivos
    // eslint-disable-next-line no-console
    console.log('testado, pode mandar pra rua')
  }

  const renderForm = (formProps: FormRenderProps<ConfiguracaoAnexoArquivosModel>) => (
    <Box>
      <VFlow>
        <HFlow hSpacing={0.5}>
          <Heading level={3}>Habilitar anexo de arquivos</Heading>
          {isEnabled ? (
            <Tag type='success' icon='checkCircleFilled'>
              Habilitado
            </Tag>
          ) : (
            <Tag type='normal' icon='banOutline'>
              Desabilitado
            </Tag>
          )}
        </HFlow>
        <Text>
          Ao habilitar o anexo de arquivos, os profissionais de saúde poderão anexar arquivos ao prontuário de um
          cidadão caso necessário. Esses arquivos serão armazenados no diretório informado abaixo e podem ser
          visualizados no prontuário do cidadão por todos os profissionais que tiverem acesso. Imagens e documentos de
          saúde podem ocupar um espaço considerável de armazenamento e é necessário um monitoramento para garantir que a
          infraestrutura está suportando a demanda.
        </Text>

        <Grid gap={0.5}>
          <Cell size={3}>
            <TextField
              label='Diretório de destino dos arquivos'
              name={meta.diretorio}
              required
              disabled={isEnabled !== null}
            />
          </Cell>
          {isEnabled === null && (
            <Cell>
              <FormControl label={<span>&nbsp;</span>}>
                <Tooltip text={BOTAO_TESTAR_TOOLTIP_TEXT}>
                  <Button kind='normal' size='small' onClick={handleTestClick}>
                    Testar
                  </Button>
                </Tooltip>
              </FormControl>
            </Cell>
          )}
        </Grid>
        <SubmitButton kind={isEnabled ? 'normal' : 'primary'} size='small' handleSubmit={formProps.handleSubmit}>
          {isEnabled ? 'Desabilitar' : 'Habilitar'}
        </SubmitButton>
      </VFlow>
    </Box>
  )

  const initialValues = useMemo(() => ({ diretorio }), [diretorio])

  return (
    <Form<ConfiguracaoAnexoArquivosModel>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validate}
      render={renderForm}
    />
  )
}
