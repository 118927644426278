import createDecorator from 'final-form-calculate'
import { metaPath } from 'util/metaPath'

import { ParticipanteEditableTableModel } from './ParticipanteEditableTable'

const meta = metaPath<{ [x: string]: ParticipanteEditableTableModel }>()

export const PARTICIPANTE_EDITABLE_TABLE_PREFIX = 'new'

export const participantesDecorator = createDecorator({
  field: [meta[PARTICIPANTE_EDITABLE_TABLE_PREFIX].isCidadaoSemCadastroFormOpen.absolutePath()],
  updates: (value: boolean, _: string, allValues: { [x: string]: ParticipanteEditableTableModel }) => ({
    [PARTICIPANTE_EDITABLE_TABLE_PREFIX]: {
      ...allValues[PARTICIPANTE_EDITABLE_TABLE_PREFIX],
      cidadao: !value ? allValues[PARTICIPANTE_EDITABLE_TABLE_PREFIX]?.cidadao : null,
      cidadaoSemCadastro: value ? allValues[PARTICIPANTE_EDITABLE_TABLE_PREFIX]?.cidadaoSemCadastro : null,
    },
  }),
})
