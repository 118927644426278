import { HeadingSection } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { useCidadaoFciAtualizacaoQuery } from 'graphql/hooks.generated'
import React, { useMemo } from 'react'
import { useRouteMatch } from 'react-router'

import { CidadaoFciFormModel } from '../types/model-cidadaoFci'
import { CidadaoFciDadosForm } from './CidadaoFciDadosForm'

interface UrlParams {
  cidadaoId: string
}

export function CidadaoFciEditView() {
  const match = useRouteMatch<UrlParams>()
  const cidadaoId = match.params.cidadaoId

  const {
    data: { cidadao },
  } = useCidadaoFciAtualizacaoQuery({
    variables: {
      id: cidadaoId,
    },
    fetchPolicy: 'network-only',
  })

  const initialValues = useMemo<CidadaoFciFormModel>(
    () => ({
      id: cidadao?.id,
    }),
    [cidadao]
  )

  return (
    <PageContent type='filled'>
      <Breadcrumb title='Visualização' to={`/cidadao/${cidadaoId}`} />
      <Breadcrumb title='Edição' />
      <HeadingSection level={1} title='Cadastro do cidadão'>
        <>Novo cadastro do cidadão</>
      </HeadingSection>
      <CidadaoFciDadosForm initialValues={initialValues} />
    </PageContent>
  )
}
