import { Alert, Heading, VFlow } from 'bold-ui'
import { BoxGroup } from 'components/BoxGroup'
import React, { useState } from 'react'

import HabilitarAnexoArquivosBox from './components/HabilitarAnexoArquivosBox'

const getRandomIsEnabled = () => {
  const values = [null, true, false]
  const randomIndex = Math.floor(Math.random() * values.length)
  return values[randomIndex]
}

export function AnexoArquivosView() {
  const [isEnabled, setIsEnabled] = useState(getRandomIsEnabled())

  const handleToggleEnabled = (enabled: boolean) => {
    setIsEnabled(enabled)
  }
  return (
    <VFlow>
      <VFlow vSpacing={0.5}>
        <Heading level={2}>Anexo de arquivos na instalação</Heading>
        {!isEnabled && (
          <Alert type='warning'>
            Para habilitar essa funcionalidade, certifique-se de que seu servidor possui a infraestrutura necessária
            para o armazenamento dos arquivos.
          </Alert>
        )}
      </VFlow>
      <BoxGroup>
        <HabilitarAnexoArquivosBox isEnabled={isEnabled} onToggleEnabled={handleToggleEnabled} />
      </BoxGroup>
    </VFlow>
  )
}
