import { useCidadaoFciVisualizacaoQuery } from 'graphql/hooks.generated'
import React from 'react'

interface CidadaoFciInformacoesViewProps {
  cidadaoId: ID
}

export function CidadaoFciInformacoesView(props: CidadaoFciInformacoesViewProps) {
  const { cidadaoId } = props

  const { data } = useCidadaoFciVisualizacaoQuery({
    variables: {
      id: cidadaoId,
    },
    fetchPolicy: 'network-only',
  })

  return data?.cidadao ? <>Visualização dos dados do cidadão de id {data.cidadao.id} </> : <></>
}
