/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow, Icon, Link, Text, Tooltip, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb/Breadcrumb'
import { Form, FormFooter, FormRenderProps } from 'components/form'
import { PageContent } from 'components/layout/PageContent'
import { RelatoriosButtonGroup } from 'components/relatorios-buttons/RelatoriosButtonGroup'
import { usePagedTableProps } from 'components/table'
import { TitleGroup } from 'components/TitleGroup'
import { useFlags } from 'config/useFlagsContext'
import { useBuscaCidadaoListagemLazyQuery } from 'graphql/hooks.generated'
import { ArquivoIdentificadorEnum, ArquivoStatusEnum } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import useAtmosphere from 'hooks/useAtmosphere'
import { useServerTime } from 'hooks/useServerTime'
import image from 'images/403-forbidden.png'
import { Fragment, useEffect, useState } from 'react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import Permissions from 'types/Permissions'
import { isUndefinedOrNull } from 'util/checks'
import { downloadFile } from 'util/downloadFile'
import { formatRelatorioFileName } from 'view/arquivo/downloadsArquivo'
import { ArquivoFormato } from 'view/arquivo/model-arquivo'
import { RelatoriosRecentes } from 'view/arquivo/RelatoriosRecentes'
import { LinkVerEquipesVinculadasView } from 'view/vinculacao-equipes/LinkVerEquipesVinculadasView'

import { AcompanhamentoCondicaoSaudeForm } from './AcompanhamentoCondicaoSaudeForm'
import { AcompanhamentoCondicaoSaudeListing } from './AcompanhamentoCondicaoSaudeListing'
import { RelatoriosOperacionaisModal } from './components/RelatoriosOperacionaisModal'
import convertModelToInput from './converter-acompanhamentoCondicaoSaude'
import { DoSearchPanel } from './DoSearchPanel'
import { downloadAcompanhamentoCondicaoSaudeCSV } from './downloadRelatorio'
import {
  ACOMPANHAMENTO_CONDICAO_SAUDE_ATMOSPHERE_PATH,
  AcompanhamentoCondicaoSaudeFormModel,
  AgeRangeFilter,
  RelatorioOperacionalAuthorization,
} from './model-acompanhamentoCondicaoSaude'
import { validate as validateForm } from './validator-acompanhamentoCondicaoSaude'

const HINT_BUSCA_MUITOS_RESULTADOS =
  'A busca de cidadãos retornou mais de 500 resultados. Refine melhor a busca para poder gerar relatórios operacionais.'
const HINT_BUSCA_NENHUM_RESULTADO = 'A busca de cidadãos não encontrou nenhum resultado com os filtros aplicados.'
const HINT_ACESSO_NAO_PERMITIDO = 'Acesso não permitido.'
const GERAR_RELATORIO_MAX_ELEMENTS = 500

export const RELATORIOS_OPERACIONAIS_URL = 'relatorios-operacionais'

// TODO Royal Flush - Filtros Aplicados do Acompanhamento #20112
export default function AcompanhamentoCondicaoSaudeView() {
  const [currentFilter, setCurrentFilter] = useState<AcompanhamentoCondicaoSaudeFormModel>()
  const [executeQuery, { loading, data }] = useBuscaCidadaoListagemLazyQuery()
  const { ACOMPANHAMENTO_CONDICAO_SAUDE_ENABLED, ACOMPANHAMENTO_CONDICAO_SAUDE_CSV } = useFlags()
  const { getServerTimeNow } = useServerTime()
  const { analytics } = useFirebase()
  const now = getServerTimeNow()
  const history = useHistory()
  const match = useRouteMatch()
  const fileNameFormatted = formatRelatorioFileName(ACOMPANHAMENTO_CONDICAO_SAUDE_ATMOSPHERE_PATH, now)
  const {
    data: {
      profissional: { id: usuarioId },
    },
    hasAuthorization,
  } = useSession()

  const [shouldRefetch, setShouldRefetch] = useState(false)
  const [statusRelatorio, setStatusRelatorio] = useState<ArquivoStatusEnum>()

  const isCoordenador = hasAuthorization(Permissions.acompanhamentos.condicoesDeSaude.todasAsEquipes)

  const cidadaos = data?.acompanhamentoCondicaoSaude

  const permissions: RelatorioOperacionalAuthorization = {
    hasCriancaPermission: hasAuthorization(Permissions.relatorios.operacional.crianca),
    hasGestantePermission: hasAuthorization(Permissions.relatorios.operacional.gestante),
    hasHipertensaoPermission: hasAuthorization(Permissions.relatorios.operacional.riscoCardiovascular),
  }

  const canAcessarModalRelatorio =
    permissions.hasCriancaPermission || permissions.hasGestantePermission || permissions.hasHipertensaoPermission

  const disableGerarRelatorio =
    loading ||
    cidadaos?.pageInfo.totalElements > GERAR_RELATORIO_MAX_ELEMENTS ||
    cidadaos?.pageInfo.totalElements === 0 ||
    !canAcessarModalRelatorio

  const hintBtnGerarRelatorio = canAcessarModalRelatorio
    ? disableGerarRelatorio && cidadaos?.pageInfo?.totalElements > GERAR_RELATORIO_MAX_ELEMENTS
      ? HINT_BUSCA_MUITOS_RESULTADOS
      : HINT_BUSCA_NENHUM_RESULTADO
    : HINT_ACESSO_NAO_PERMITIDO

  const handleModalOpen = () => {
    history.push(`${match.url}/${RELATORIOS_OPERACIONAIS_URL}`)
  }

  const renderRelatoriosOperacionaisButton = (
    <Fragment>
      <Tooltip text={disableGerarRelatorio && hintBtnGerarRelatorio}>
        <Button kind='primary' disabled={disableGerarRelatorio} onClick={handleModalOpen}>
          <HFlow hSpacing={0.5}>
            <Icon icon='fileWithItemsOutline' />
            Gerar relatório operacional
          </HFlow>
        </Button>
      </Tooltip>
      <Switch>
        <Route path={`${match.url}/${RELATORIOS_OPERACIONAIS_URL}`}>
          {currentFilter && (
            <RelatoriosOperacionaisModal filter={convertModelToInput(currentFilter)} permissions={permissions} />
          )}
        </Route>
      </Switch>
    </Fragment>
  )

  const identificadorModuloArquivo = ArquivoIdentificadorEnum.RELATORIO_ACOMPANHAMENTO_CONDICAO_SAUDE

  const tableProps = usePagedTableProps({
    result: cidadaos,
    loading: loading,
    onChange: setCurrentFilter,
  })

  useAtmosphere<ArquivoFormato>({
    topic: `${ACOMPANHAMENTO_CONDICAO_SAUDE_ATMOSPHERE_PATH}/${usuarioId}`,
    onMessage: (responseBody) => {
      if (responseBody.arquivo !== null) {
        const arquivo = new Uint8Array(responseBody.arquivo)
        downloadFile(new Blob([arquivo]), `${fileNameFormatted}.${responseBody.formato.toLocaleLowerCase()}`)
      }
    },
  })

  useEffect(() => {
    currentFilter && executeQuery({ variables: { input: { ...convertModelToInput(currentFilter) } } })
  }, [currentFilter, executeQuery])

  const afterSubmit = () => {
    setShouldRefetch(true)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleExportarCSVClick = () => {
    analytics.logEvent('exportar_csv_acompanhamento_condicoes_saude')
    downloadAcompanhamentoCondicaoSaudeCSV(convertModelToInput(currentFilter))
    afterSubmit()
  }

  const renderForm = (formProps: FormRenderProps<AcompanhamentoCondicaoSaudeFormModel>) => {
    const { values } = formProps
    const showTable = loading || !isUndefinedOrNull(cidadaos)

    return (
      <Fragment>
        <Breadcrumb title='Acompanhamento de condições de saúde' />
        <VFlow
          style={css`
            border-bottom: 1px solid lightgray;
          `}
        >
          <PageContent type='filled'>
            <HFlow justifyContent='space-between' style={styles.titleContent}>
              <TitleGroup title='Acompanhamento de condições de saúde' />
              {ACOMPANHAMENTO_CONDICAO_SAUDE_CSV && (
                <RelatoriosRecentes
                  shouldRefetch={shouldRefetch}
                  setShouldRefetch={setShouldRefetch}
                  identificadorModuloArquivo={identificadorModuloArquivo}
                  setStatusRelatorio={setStatusRelatorio}
                />
              )}
            </HFlow>
            <AcompanhamentoCondicaoSaudeForm {...formProps} />
          </PageContent>
        </VFlow>
        <PageContent>
          <VFlow>
            {showTable ? (
              <Fragment>
                <TitleGroup title='Cidadãos encontrados' style={styles.titleCidadaosEncontrados} />
                <VFlow>
                  <LinkVerEquipesVinculadasView equipe={values?.equipeResponsavel} />
                  <AcompanhamentoCondicaoSaudeListing {...tableProps} />
                </VFlow>
                <FormFooter>
                  <RelatoriosButtonGroup
                    shouldRefetch={shouldRefetch}
                    statusRelatorio={statusRelatorio}
                    numberOfElements={cidadaos?.content.length}
                    onClickExportarCSV={ACOMPANHAMENTO_CONDICAO_SAUDE_CSV && handleExportarCSVClick}
                    otherButtons={renderRelatoriosOperacionaisButton}
                  />
                </FormFooter>
              </Fragment>
            ) : (
              <DoSearchPanel isCoordenador={isCoordenador} equipeResponsavelId={values.equipeResponsavelId} />
            )}
          </VFlow>
        </PageContent>
      </Fragment>
    )
  }

  return (
    <Fragment>
      {ACOMPANHAMENTO_CONDICAO_SAUDE_ENABLED ? (
        <Form<AcompanhamentoCondicaoSaudeFormModel>
          render={renderForm}
          onSubmit={setCurrentFilter}
          validate={validateForm}
          initialValues={{ faixaEtariaFilter: AgeRangeFilter.TODAS_FAIXAS }}
        />
      ) : (
        <PageContent>
          <Grid style={{ paddingTop: '10rem' }} alignItems='center' justifyContent='space-around'>
            <Cell>
              <VFlow>
                <VFlow vSpacing={0.5}>
                  <h1>Estamos em manutenção</h1>
                  <p>Pedimos desculpas pelo imprevisto, mas não se preocupe, logo a situação será resolvida.</p>
                </VFlow>

                <p>
                  <Button kind='primary' onClick={history.goBack}>
                    <Icon icon='arrowLeft' style={{ marginRight: '0.25rem' }} />
                    <Text color='inherit'>Retornar à página anterior</Text>
                  </Button>
                </p>

                <p>
                  <Link component={RouterLink} to='/'>
                    Acessar página inicial
                  </Link>
                </p>
              </VFlow>
            </Cell>
            <Cell>
              <img
                src={image}
                alt='Duas pessoas olhando para uma porta protegida por um lacre, representando que o acesso está proibido'
              />
            </Cell>
          </Grid>
        </PageContent>
      )}
    </Fragment>
  )
}

const styles = {
  titleContent: css`
    margin: 1rem 0;
  `,
  titleCidadaosEncontrados: css`
    margin-bottom: 1rem;
  `,
}
