import { Breadcrumb } from 'components/breadcrumb'
import { ATIVIDADE_COLETIVA_ATIVIDADE_SAUDE_PATH } from 'components/layout/SideMenu/SideMenuItems'
import { PecSwitch, PrivateRoute } from 'components/route'
import React, { Fragment } from 'react'
import Permissions from 'types/Permissions'

import { AtividadeColetivaSaudeView } from './AtividadeColetivaSaudeView'
import { AtividadeColetivaVisualizarSaudeView } from './AtividadeColetivaVisualizarSaudeView'
import { AtividadeColetivaSaudeListaView } from './lista/AtividadeColetivaSaudeListaView'

export function AtividadeColetivaSaudeRootView() {
  return (
    <Fragment>
      <Breadcrumb title='Atividade coletiva em saúde' />

      <PecSwitch>
        <PrivateRoute
          path={`${ATIVIDADE_COLETIVA_ATIVIDADE_SAUDE_PATH}/registrar`}
          component={AtividadeColetivaSaudeView}
          permission={Permissions.cds.fichaDeAtividadeColetiva.cadastrarEditarEExcluir}
        />
        <PrivateRoute
          path={`${ATIVIDADE_COLETIVA_ATIVIDADE_SAUDE_PATH}/visualizar/:atividadeColetivaId`}
          component={AtividadeColetivaVisualizarSaudeView}
          permission={Permissions.cds.fichaDeAtividadeColetiva}
        />
        <PrivateRoute
          exact
          path={ATIVIDADE_COLETIVA_ATIVIDADE_SAUDE_PATH}
          component={AtividadeColetivaSaudeListaView}
          permission={Permissions.cds.fichaDeAtividadeColetiva}
        />
      </PecSwitch>
    </Fragment>
  )
}
