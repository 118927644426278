import { CiapCidSelectModel, isCiap } from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { AcompanhamentoCondicaoSaudeQueryInput, FaixaEtariaInput } from 'graphql/types.generated'
import { isNumber, partition } from 'lodash'
import { idGrupoCondicao } from 'types/enums'

import {
  AcompanhamentoCondicaoSaudeFormModel,
  AgeRangeFilter,
  faixaEtariaRecord,
} from './model-acompanhamentoCondicaoSaude'

export default (model: AcompanhamentoCondicaoSaudeFormModel): AcompanhamentoCondicaoSaudeQueryInput => {
  const [ciaps, cids] = partition(model?.problemasCondicoes, (item) => isCiap(item))
  return {
    ciaps: ciaps.map((item: CiapCidSelectModel) => item.codigo),
    cid10: cids.map((item: CiapCidSelectModel) => item.codigo),
    grupoCondicao: model?.grupoCondicao?.map((item) => idGrupoCondicao[item.grupoCondicao]),
    somenteProblemasCondicoesAtivas: model?.ativoListaProblema ? model.ativoListaProblema : false,
    unidadeResponsavelId: model?.unidadeResponsavelId,
    equipeResponsavelId: model.isCoordenador ? model?.equipeResponsavel?.id : model?.equipeResponsavelId,
    sexo: model?.sexo,
    identidadeGenero: model?.identidadeGenero,
    faixaEtaria: AgeRangeFilter.TODAS_FAIXAS !== model?.faixaEtariaFilter ? mountFaixaEtaria(model) : null,
    periodoUltimoAtendimento: model?.periodoUltimoAtendimento,
    microareas: model?.microareas,
    pageParams: {
      sort: ['cidadao', 'endereco', 'dataAtendimento'],
      ...model.pageParams,
    },
  }
}

function mountFaixaEtaria(model: AcompanhamentoCondicaoSaudeFormModel): FaixaEtariaInput {
  const { faixaEtariaFilter, faixaEtaria } = model

  return faixaEtariaFilter !== AgeRangeFilter.OUTRA
    ? faixaEtariaRecord[faixaEtariaFilter]
    : {
        idadeMinima: faixaEtaria?.firstValue,
        idadeMaxima: faixaEtaria?.secondValue,
        tipoFaixaEtaria:
          isNumber(faixaEtaria?.firstValue) || isNumber(faixaEtaria?.secondValue) ? faixaEtaria?.tipoFaixaEtaria : null,
      }
}
