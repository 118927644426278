import api from 'api'
import { AcompanhamentoCondicaoSaudeQueryInput } from 'graphql/types.generated'
import { defaultHandleError } from 'util/downloadFile'
import { generatePdf } from 'util/generatePdf'

import { TipoRelatorioOperacionalEnum } from './model-acompanhamentoCondicaoSaude'

export const downloadAcompanhamentoCondicaoSaudeCSV = (input: AcompanhamentoCondicaoSaudeQueryInput) => {
  return api.acompanhamentoSaude.exportarCSV(input).catch(defaultHandleError)
}

export const downloadRelatorio = (
  tipoRelatorio: TipoRelatorioOperacionalEnum,
  filter: AcompanhamentoCondicaoSaudeQueryInput
) => {
  switch (tipoRelatorio) {
    case TipoRelatorioOperacionalEnum.CRIANCA:
      return downloadRelatorioCrianca(filter)
    case TipoRelatorioOperacionalEnum.GESTANTE:
      return downloadRelatorioGestante(filter)
    default:
      return downloadRelatorioRiscoCardioVascular(filter)
  }
}

const downloadRelatorioCrianca = (filter: AcompanhamentoCondicaoSaudeQueryInput) => {
  return api.acompanhamentoSaude
    .downloadRelatorioOperacionalCrianca(filter)
    .then((response) => {
      generatePdf(
        new Blob([response.data], { type: 'application/pdf' }),
        'Relatório operacional de Crianças menores de 5 anos'
      )
    })
    .catch((error) => {
      return handleErrorDownloadRelatorio(error)
    })
}

const downloadRelatorioGestante = (filter: AcompanhamentoCondicaoSaudeQueryInput) => {
  return api.acompanhamentoSaude
    .downloadRelatorioOperacionalGestante(filter)
    .then((response) => {
      generatePdf(new Blob([response.data], { type: 'application/pdf' }), 'Relatório operacional de Gestante/Puérpera')
    })
    .catch((error) => {
      return handleErrorDownloadRelatorio(error)
    })
}

const downloadRelatorioRiscoCardioVascular = (filter: AcompanhamentoCondicaoSaudeQueryInput) => {
  return api.acompanhamentoSaude
    .downloadRelatorioOperacionalRiscoCardiovascular(filter)
    .then((response) => {
      generatePdf(
        new Blob([response.data], { type: 'application/pdf' }),
        'Relatório operacional de Risco cardiovascular'
      )
    })
    .catch((error) => {
      return handleErrorDownloadRelatorio(error)
    })
}

const handleErrorDownloadRelatorio = (error) => {
  return new Promise<any>((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener('loadend', (e) => {
      reject((e.srcElement as any).result)
    })
    reader.readAsText(error.response.data)
  })
}
