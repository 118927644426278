import { Alert, Button, Cell, DataTable, Grid, HFlow, Icon, TableFooter, Text, Tooltip, VFlow } from 'bold-ui'
import {
  CheckboxField,
  CidadaoSelectField,
  CidadaoSelectFieldModel,
  CidadaoSelectFieldProps,
  CnsField,
  CpfField,
  DateField,
  DecimalField,
  NumberField,
  SexoSelectField,
  SimNaoRadioGroupField,
  SwitchField,
  TextField,
} from 'components/form'
import { Cns, Cpf } from 'components/label'
import { TableBox } from 'components/table/TableBox'
import { usePagination } from 'components/table/usePagination'
import { css } from 'emotion'
import { Profissional } from 'graphql/types.generated'
import React, { Fragment } from 'react'
import { calcularIMC } from 'util/atendimento'
import { humanizeAge } from 'util/date/humanize-age'
import { MetaArray } from 'util/metaPath'
import { formatNumber, parseNumber } from 'util/number'
import { useEditableListField } from 'view/atendimentos/detail/components/EditableList'
import { EditableListForm, EditableListFormRenderProps } from 'view/atendimentos/detail/components/EditableListForm'
import { AlertImc } from 'view/atendimentos/detail/soap/aside/medicoes/components/AlertImc'

import { PARTICIPANTE_EDITABLE_TABLE_PREFIX, participantesDecorator } from './calculator-participantesEditableTable'
import { participanteEditableTableValidator } from './validator-participantesEditableTable'

export type ProfissionalCpfCnsIdModel = Pick<Profissional, 'id' | 'cpf' | 'cns'>

export type CidadaoSemCadastroFormModel = Pick<
  CidadaoSelectFieldModel,
  'nome' | 'cpf' | 'cns' | 'dataNascimento' | 'sexo'
>

type ParticipanteEditableTableProps = Pick<CidadaoSelectFieldProps, 'required' | 'disabled'> & {
  profissionalResponsavel: ProfissionalCpfCnsIdModel
  profissionaisEnvolvidos: ProfissionalCpfCnsIdModel[]
  isPraticaSaudePnct: boolean
  name: MetaArray<ParticipanteEditableTableModel>
  now: Date
}

export interface InfoParticipanteFormModel {
  peso: string
  altura: string
  avaliacaoAlterada: boolean
  pnctAbandonouGrupo: boolean
  pnctCessouHabitoFumar: boolean
}

export interface ParticipanteEditableTableModel {
  _id: ID
  cidadao: CidadaoSelectFieldModel
  infoParticipante: InfoParticipanteFormModel
  cidadaoSemCadastro: CidadaoSemCadastroFormModel
  isCidadaoSemCadastroFormOpen: boolean
}

export function ParticipanteEditableTable(props: ParticipanteEditableTableProps) {
  const { name, now, isPraticaSaudePnct, profissionalResponsavel, profissionaisEnvolvidos, required } = props

  const {
    handleSubmit,
    removeItem,
    input: { value },
  } = useEditableListField({ name })

  const allItems: ParticipanteEditableTableModel[] = (value || []).slice().reverse()
  const { paginatedItems, tableProps } = usePagination({ items: allItems, initialPageSize: 5 })

  const renderForm = (renderProps: EditableListFormRenderProps<ParticipanteEditableTableModel>) => {
    const { name, handleSubmit, values } = renderProps

    return (
      <Grid gap={0.5} style={styles.tableHeader}>
        <Cell lg={4} md={3}>
          <CidadaoSelectField
            name={name.cidadao}
            label='Cidadão'
            placeholder='Pesquise por nome, CPF ou CNS'
            required={required}
            disabled={values?.isCidadaoSemCadastroFormOpen}
          />
        </Cell>

        <Cell size={2} style={styles.inputAvaliacaoAlterada}>
          <CheckboxField name={name.infoParticipante.avaliacaoAlterada} label='Avaliação alterada' />
        </Cell>

        <Cell size={1}>
          <DecimalField name={name.infoParticipante.peso} label='Peso (Kg)' min={0.5} max={500} maxLength={6} />
        </Cell>

        <Cell size={1}>
          <NumberField name={name.infoParticipante.altura} label='Altura (cm)' min={20} max={250} maxLength={3} />
        </Cell>

        <Cell lg={4} md={5}>
          <HFlow hSpacing={1.5}>
            <SimNaoRadioGroupField
              name={name.infoParticipante.pnctCessouHabitoFumar}
              label='Cessou o hábito de fumar?'
              disabled={!isPraticaSaudePnct}
            />
            <SimNaoRadioGroupField
              name={name.infoParticipante.pnctAbandonouGrupo}
              label='Abandonou o grupo?'
              disabled={!isPraticaSaudePnct}
            />
          </HFlow>
        </Cell>

        <Cell size={12}>
          <SwitchField
            label='Adicionar cidadão sem cadastro na base local'
            initialValue={false}
            name={name.isCidadaoSemCadastroFormOpen}
          />
        </Cell>

        {values?.isCidadaoSemCadastroFormOpen && (
          <Fragment>
            <Cell size={3}>
              <TextField
                name={name.cidadaoSemCadastro.nome}
                label='Nome do cidadão'
                maxLength={70}
                required
                uppercase
              />
            </Cell>

            <Cell size={2}>
              <CpfField label='CPF' name={name.cidadaoSemCadastro.cpf} />
            </Cell>

            <Cell size={2}>
              <CnsField label='CNS' name={name.cidadaoSemCadastro.cns} />
            </Cell>

            <Cell size={2}>
              <DateField
                name={name.cidadaoSemCadastro.dataNascimento}
                maxDate={now}
                label='Data de nascimento'
                required
              />
            </Cell>

            <Cell size={2}>
              <SexoSelectField label='Sexo' name={name.cidadaoSemCadastro.sexo} required />
            </Cell>

            <Cell size={7}>
              <Alert type='warning'>
                O registro é feito apenas na atividade coletiva, os campos não substituem o cadastro do cidadão.
              </Alert>
            </Cell>
          </Fragment>
        )}

        <Cell size={12}>
          <HFlow justifyContent='flex-end'>
            <Button kind='primary' onClick={handleSubmit} size='small'>
              Adicionar
            </Button>
          </HFlow>
        </Cell>
      </Grid>
    )
  }

  const renderHeader = () => {
    return (
      <EditableListForm<ParticipanteEditableTableModel>
        render={renderForm}
        onSubmit={handleSubmit}
        validate={participanteEditableTableValidator(allItems, profissionalResponsavel, profissionaisEnvolvidos)}
        decorators={[participantesDecorator]}
        prefix={PARTICIPANTE_EDITABLE_TABLE_PREFIX}
      />
    )
  }

  const renderImc = (row: ParticipanteEditableTableModel) => {
    const imc = calcularIMC(parseNumber(row.infoParticipante.peso), parseNumber(row.infoParticipante.altura))

    return (
      <HFlow alignItems='center'>
        <Text>{formatNumber(imc)}</Text>
        <AlertImc
          // TODO Foundation 22576 - Alterar para a data da atividade.
          dataMedicao={now.getTime()}
          imc={formatNumber(imc)}
          dataNascimento={row.cidadao?.dataNascimento ?? row.cidadaoSemCadastro?.dataNascimento}
          sexo={row.cidadao?.sexo ?? row.cidadaoSemCadastro?.sexo}
        />
      </HFlow>
    )
  }

  const renderButtons = (row: ParticipanteEditableTableModel) => {
    return (
      <Tooltip text='Excluir'>
        <Button type='button' kind='normal' skin='ghost' size='small' onClick={() => removeItem(row)}>
          <Icon icon='trashOutline' />
        </Button>
      </Tooltip>
    )
  }

  const infoCidadaoColumn = (participanteTableModel: ParticipanteEditableTableModel) => {
    const cidadaoInfoColumn = participanteTableModel.cidadao
      ? {
          ...participanteTableModel.cidadao,
          nome: participanteTableModel.cidadao?.nomeSocial ?? participanteTableModel.cidadao?.nome,
        }
      : participanteTableModel.cidadaoSemCadastro

    return (
      <VFlow vSpacing={0.1} style={styles.tableRowCidadao}>
        <Text fontWeight='bold'>{cidadaoInfoColumn.nome.titleCase()}</Text>
        {(cidadaoInfoColumn?.cpf && <Cpf value={cidadaoInfoColumn.cpf} />) ||
          (cidadaoInfoColumn?.cns && <Cns value={cidadaoInfoColumn.cns} />)}
        <Text>
          {humanizeAge(cidadaoInfoColumn.dataNascimento)} | {cidadaoInfoColumn.sexo.capitalize()}
        </Text>
      </VFlow>
    )
  }

  return (
    <TableBox header={renderHeader()}>
      <DataTable<ParticipanteEditableTableModel>
        rows={paginatedItems}
        columns={[
          {
            name: 'nome',
            header: 'Nome',
            render: (row) => infoCidadaoColumn(row),
            style: styles.tableRowNome,
          },
          {
            name: 'avaliacaoAlterada',
            header: 'Avaliação alterada',
            render: (row) => (row.infoParticipante?.avaliacaoAlterada ? 'Sim' : 'Não'),
          },
          {
            name: 'peso',
            header: 'Peso (Kg)',
            render: (row) => (row.infoParticipante?.peso ? row.infoParticipante.peso : '-'),
          },
          {
            name: 'altura',
            header: 'Altura (cm)',
            render: (row) => (row.infoParticipante?.altura ? row.infoParticipante.altura : '-'),
          },
          {
            name: 'imc',
            header: 'IMC',
            style: styles.tableRowIMC,
            render: (row) => (row.infoParticipante?.peso && row.infoParticipante?.altura ? renderImc(row) : '-'),
          },
          isPraticaSaudePnct && {
            name: 'pnctAbandonouGrupo',
            header: 'Abandonou o grupo?',
            render: (row) => (row.infoParticipante?.pnctAbandonouGrupo ? 'Sim' : 'Não'),
            style: styles.tableRowPnct,
          },
          isPraticaSaudePnct && {
            name: 'pnctCessouFumar',
            header: 'Cessou o hábito de fumar?',
            render: (row) => (row.infoParticipante?.pnctCessouHabitoFumar ? 'Sim' : 'Não'),
            style: styles.tableRowPnct,
          },
          {
            name: 'buttons',
            render: renderButtons,
            style: styles.tableRowButtons,
          },
        ].filterNotFalsy()}
      />
      <TableFooter style={styles.tableFooter} {...tableProps} />
    </TableBox>
  )
}

const styles = {
  tableHeader: css`
    padding-block: 0.5rem;
  `,
  tableFooter: css`
    border-top: 0;
  `,
  tableRowNome: css`
    width: 15rem;
  `,
  tableRowCidadao: css`
    margin: 1rem 0;
  `,
  tableRowIMC: css`
    width: 20rem;
  `,
  tableRowPnct: css`
    width: 9rem;
  `,
  tableRowButtons: css`
    width: 3.5rem;
    text-align: right;
  `,
  inputAvaliacaoAlterada: css`
    margin-top: 1.75rem;
  `,
}
