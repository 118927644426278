import { AtendimentoIndividualRetificacaoViewQuery } from 'graphql/types.generated'
import { convertSubjetivoQueryRetificacaoToForm } from 'view/atendimentos/detail/soap/subjetivo/converter'

import { SoapState } from '../atendimentos/atendimento-individual/model'

type AtendimentoIndividualRetificacaoModel = AtendimentoIndividualRetificacaoViewQuery['atendimento']['atendimentoProfissional']

export const initialValueRetificacao = (
  atendimentoIndividualRetificacao: AtendimentoIndividualRetificacaoModel
): SoapState => {
  const { evolucaoSubjetivo } = atendimentoIndividualRetificacao
  return {
    openedAccordions: ['S', 'O', 'P', 'D'],
    //TODO (RNG): Implementar conversores do model para o form do SoapState (#22001)
    subjetivo: convertSubjetivoQueryRetificacaoToForm(evolucaoSubjetivo),
    medicoesAnteriores: [],
    finalizacao: {},
    plano: {},
    objetivo: {},
  }
}
