import { Cell, Grid, Heading, InfoLabel, Text, VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageLoading } from 'components/loading/PageLoading'
import { LotacaoResponsavelCard } from 'components/lotacao-responsavel/LotacaoResponsavelCard'
import { TitleGroup } from 'components/TitleGroup'
import { css } from 'emotion'
import { useAtividadeColetivaVisualizarSaudeQuery } from 'graphql/hooks.generated'
import React, { Fragment } from 'react'
import { Redirect, useParams } from 'react-router'
import { turnoRecord } from 'types/enums'
import { formatDate } from 'util/date/formatDate'

import { LotacoesEnvolvidasTable } from '../componentes/LotacoesEnvolvidasTable'
import { ParticipantesTable } from '../componentes/ParticipantesTable'
import { praticaSaudeRecord, publicoAlvoRecord, temaSaudeRecord, tipoAtividadeRecord } from '../model-atividadeColetiva'
import { isOnlyEducacaoPseSelected, isPraticaSaudePnct } from './utils-atividadeColetivaSaude'

interface AtividadeColetivaVisualizarSaudeViewProps {
  atividadeColetivaId: ID
}

export const AtividadeColetivaVisualizarSaudeView = () => {
  const { atividadeColetivaId } = useParams<AtividadeColetivaVisualizarSaudeViewProps>()

  const {
    data: { atividadeColetiva },
    loading,
  } = useAtividadeColetivaVisualizarSaudeQuery({
    variables: { atividadeColetivaId },
  })

  const anotacoesTextOnly = atividadeColetiva?.anotacoes?.removeTags()

  if (loading) {
    return <PageLoading message='Carregando' />
  } else if (!atividadeColetiva) {
    return <Redirect to='/404' />
  }

  return (
    <Fragment>
      <Breadcrumb title='Visualização de registro de atividade coletiva em saúde' />
      <PageContent type='filled' style={styles.pageContent}>
        <TitleGroup title='Registro de atividade coletiva em saúde' style={styles.header} />

        <Grid gap={2}>
          <Cell size={12}>
            <VFlow vSpacing={0.5}>
              <Heading level={3}>Responsabilidade de registro</Heading>
              <LotacaoResponsavelCard responsavel={atividadeColetiva.lotacaoResponsavel} />
            </VFlow>
          </Cell>

          <Cell size={3} style={styles.section}>
            <InfoLabel title='Data da atividade'>{formatDate(atividadeColetiva.dataAtividade)}</InfoLabel>
          </Cell>

          <Cell size={3} style={styles.section}>
            <InfoLabel title='Turno'>{turnoRecord[atividadeColetiva.turno]}</InfoLabel>
          </Cell>

          <Cell size={3} style={styles.section}>
            <InfoLabel title='Nº de participantes'>{atividadeColetiva.numeroParticipantes}</InfoLabel>
          </Cell>

          <Cell size={3} style={styles.section}>
            {/* TODO Foundation #21215: alterar após conclusão do fluxo de participantes */}
            <InfoLabel title='Nº de avaliações alteradas'>{atividadeColetiva.numeroParticipantes}</InfoLabel>
          </Cell>

          <Cell size={3}>
            <InfoLabel title='Programa saúde na escola'>
              {(atividadeColetiva.atividadeEducacaoPse && atividadeColetiva.atividadeSaudePse && 'Educação, Saúde') ||
                (atividadeColetiva.atividadeEducacaoPse && 'Educação') ||
                (atividadeColetiva.atividadeSaudePse && 'Saúde')}
            </InfoLabel>
          </Cell>

          <Cell size={9} style={styles.wordBreakSection}>
            <InfoLabel title='Local da atividade'>
              {atividadeColetiva.inepLocalAtividade?.nomeEstabelecimento ??
                atividadeColetiva.unidadeSaudeLocalAtividade?.nome ??
                atividadeColetiva.outraLocalidade}
            </InfoLabel>
          </Cell>

          <Cell size={12} style={styles.section}>
            <Heading level={2} style={styles.title}>
              Profissionais envolvidos
            </Heading>
          </Cell>

          <Cell size={12}>
            {isOnlyEducacaoPseSelected(atividadeColetiva) ? (
              'Não é possível cadastrar profissionais para atividades de educação do Programa Saúde na Escola.'
            ) : (
              <LotacoesEnvolvidasTable items={atividadeColetiva.lotacoesEnvolvidas ?? []} />
            )}
          </Cell>

          <Cell size={12} style={styles.section}>
            <Heading level={2} style={styles.title}>
              Dados da atividade
            </Heading>
          </Cell>

          <Cell size={4}>
            <InfoLabel title='Tipo de atividade'>{tipoAtividadeRecord[atividadeColetiva.tipoAtividade]}</InfoLabel>
          </Cell>

          <Cell size={4}>
            <InfoLabel title='Público alvo'>
              {atividadeColetiva.publicoAlvo?.map((alvo) => publicoAlvoRecord[alvo]).join(', ')}
            </InfoLabel>
          </Cell>

          <Cell size={4}>
            <InfoLabel title='Temas para a saúde'>
              {atividadeColetiva.temasSaude?.map((tema) => temaSaudeRecord[tema]).join(', ')}
            </InfoLabel>
          </Cell>

          <Cell size={4}>
            <InfoLabel title='Práticas em saúde'>
              {atividadeColetiva.praticasSaude?.map((pratica) => praticaSaudeRecord[pratica]).join(', ')}
            </InfoLabel>
          </Cell>

          <Cell size={8}>
            <InfoLabel title='Código do SIGTAP'>
              {atividadeColetiva.procedimento &&
                `${atividadeColetiva.procedimento.descricao} - ${atividadeColetiva.procedimento.codigo}`}
            </InfoLabel>
          </Cell>

          <Cell size={12} style={styles.wordBreakSection}>
            <InfoLabel title='Anotações da atividade'>
              {anotacoesTextOnly && <Text dangerouslySetInnerHTML={{ __html: atividadeColetiva.anotacoes }} />}
            </InfoLabel>
          </Cell>

          <Cell size={12} style={styles.section}>
            <Heading level={2} style={styles.title}>
              Participantes
            </Heading>
          </Cell>
          <Cell size={12}>
            <ParticipantesTable
              items={atividadeColetiva.participantes ?? []}
              dataAtividade={atividadeColetiva.dataAtividade}
              isPraticaSaudePnct={isPraticaSaudePnct(atividadeColetiva.praticasSaude)}
            />
          </Cell>
        </Grid>
      </PageContent>
    </Fragment>
  )
}

const styles = {
  header: css`
    margin-top: 1rem;
    margin-bottom: 2rem;
  `,
  section: css`
    margin-top: 1.5rem;
  `,
  title: css`
    margin-bottom: 0.5rem;
  `,
  pageContent: css`
    padding-bottom: 3.5rem;
  `,
  wordBreakSection: css`
    word-break: break-all;
  `,
}
